import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Carousel } from "react-responsive-carousel";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export const Section1 = () => {
  const [showDialog, setShowDialog] = useState(false);
  const { t } = useTranslation();
  const data = [
    // "homepage-3.webp",
    // "homepage-4.webp",
    // "homepage-5.webp",
    // "homepage-6.webp",
    // "homepage-7.webp",
    // "homepage-8.webp",
    // "homepage-9.webp",
    "homepage-10.webp",
    "homepage-11.webp",
    "homepage-12.webp",
    "homepage-13.webp",
    "homepage-14.webp",
  ];

  return (
    <div className="w-screen h-full relative bg-black">
      <div className="w-full md:w-[99%] h-[60vh] bg-white ">
        <Carousel
          autoPlay
          infiniteLoop
          showThumbs={false}
          showIndicators={true}
          showArrows={true}
          centerMode
          emulateTouch
          swipeable
          interval={3000}
        >
          {data.map((image) => {
            return (
              <div>
                <img
                  alt=""
                  src={`/assets/banner/${image}`}
                  className="w-full h-[60vh] object-cover px-4"
                />
              </div>
            );
          })}
        </Carousel>
      </div>
      <div className="section-container text-white pt-6 pb-10">
        <div className="font-bold text-5xl font-raleway-bold text-center mx-auto">
          HMC VIETNAM
        </div>
        <div className="max-w-[500px] mt-10 text-xl text-center mx-auto">
          {t("section1-description")}
        </div>
        <div className="flex flex-wrap justify-center gap-8 mt-10">
          <img
            src="/assets/icon/facebook.webp"
            alt="social"
            className="w-16 h-16 bg-white cursor-pointer"
            onClick={() => {
              window
                .open(
                  "https://www.facebook.com/profile.php?id=100088831547036",
                  "_blank"
                )
                .focus();
            }}
          />
          <img
            src="/assets/icon/linkedin.webp"
            alt="social"
            className="w-16 h-16 bg-white cursor-pointer"
            onClick={() => {
              window
                .open("https://www.linkedin.com/company/hmcvina/", "_blank")
                .focus();
            }}
          />
          {/* <img
            src="/assets/icon/twitter.webp"
            alt="social"
            className="w-16 h-16 bg-white cursor-pointer"
          /> */}
          <img
            onClick={() =>
              window.open("https://line.me/ti/p/7NkLoynwaI ", "_blank").focus()
            }
            src="/assets/icon/line.webp"
            alt="social"
            className="w-16 h-16 bg-white cursor-pointer"
          />
        </div>
        <Popup
          modal
          trigger={
            <div className="bg-[#F1234A] p-4 cursor-pointer w-[230px] mx-auto text-center rounded-lg mt-10">
              <div
                className="text-btn uppercase"
                onClick={() => {
                  setShowDialog(true);
                }}
              >
                {t("contact now")}
              </div>
            </div>
          }
          st
          position="right center"
        >
          <div className="popup-zalo">
            <div className="text-center text-2xl border-black border-b-2 py-4">
              {t("make a call")}
            </div>
            <div className="w-full flex gap-10 flex-wrap py-6 justify-center">
              <img
                src="/assets/icon/telephone-icon.webp"
                alt="telephone"
                className="w-14 h-14 cursor-pointer"
                onClick={() => {
                  window.open("tel:84934349889");
                }}
              />
              <img
                src="/assets/icon/zalo-icon.webp"
                alt="zalo"
                className="w-14 h-14 cursor-pointer"
                onClick={() => {
                  window.open("https://zalo.me/84934349889");
                }}
              />
            </div>
          </div>
        </Popup>
      </div>
    </div>
  );
};
