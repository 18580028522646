import { useTranslation } from "react-i18next";

export default function Footer(props) {
  const { t } = useTranslation();
  return (
    <div className="relative bg-[#111828]">
      <div className="absolute z-10">
        <img
          className="object-cover w-screen"
          src="/assets/line/line2.png"
          alt="line"
        />
      </div>
      <div className="p-8 md:p-20 md:px-24">
        <div className="lg:flex lg:flex-wrap gap-10 justify-between items-center w-full relative bg-footerbg bg-cover">
          <div className="z-20 w-min-max relative text-center">
            <img
              src="/assets/footer/logo.webp"
              alt="logo"
              className="h-28 mx-auto md:translate-x-[40px] "
            />
          </div>

          <div className="text-white font-raleway mt-10 lg:mt-0 max-w-[640px]">
            <div className="gap-2 my-1 flex">
              <img
                src="/assets/icon/gps.webp"
                alt="location"
                className="h-8 inline-block mb-2"
              />
              <div className="inline-block self-center">{t("location_1")}</div>
            </div>
            <div className="gap-2 my-1 flex">
              <img
                src="/assets/icon/gps.webp"
                alt="location"
                className="h-8 inline-block mb-2"
              />
              <div className="inline-block self-center">{t("location_2")}</div>
            </div>
            <div className="gap-2 my-1 mt-2 mb-3 flex sm:block">
              <img
                src="/assets/icon/phone.webp"
                alt="location"
                className="h-7 inline-block md:mr-2"
              />
              <div className="inline-block">
                {t("General Manager")}: (+84) 915.079.389 - (+84) 978.813.436
              </div>
            </div>
            <div className="gap-2 my-1 mb-3 flex sm:block">
              <img
                src="/assets/icon/phone.webp"
                alt="location"
                className="h-7 inline-block md:mr-2"
              />
              <div className="inline-block">
                {t("Sale Manager")}: Ms. Trang - Email: trang.ha@hmcvina.com
              </div>
            </div>
            <div className="flex-row md:mt-4 flex gap-6 mb-4 justify-center md:justify-start">
              <img
                onClick={() =>
                  window
                    .open(
                      "https://www.facebook.com/profile.php?id=100088831547036",
                      "_blank"
                    )
                    .focus()
                }
                className="cursor-pointer react-button mt-3 w-[52px]"
                src="/assets/social/facebook.png"
                alt="Facebook"
              />
              {/* <img
                onClick={() =>
                  window
                    .open("https://twitter.com/hmcvietnam ", "_blank")
                    .focus()
                }
                className="cursor-pointer react-button mt-3 w-[52px]"
                src="/assets/social/twitter.png"
                alt="Twitter"
              /> */}
              <img
                onClick={() => {
                  window
                    .open("https://www.linkedin.com/company/hmcvina/", "_blank")
                    .focus();
                }}
                className="cursor-pointer react-button mt-3 w-[52px]"
                src="/assets/social/linkedin.png"
                alt="Linkedin"
              />
              <img
                onClick={() =>
                  window
                    .open("https://line.me/ti/p/7NkLoynwaI ", "_blank")
                    .focus()
                }
                className="cursor-pointer react-button mt-3 ml-1 translate-y-[6px] w-[40px] h-[40px]"
                src="/assets/social/line.webp"
                alt="Line"
              />
            </div>
          </div>

          <div className="z-20 mt-8 xl:mt-0">
            <div className="w-full">
              <iframe
                title="hmc"
                width="100%"
                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=21.173414126271275,%20105.85782555155234+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              >
                <a href="https://www.maps.ie/distance-area-calculator.html">
                  distance maps
                </a>
              </iframe>
            </div>
          </div>
        </div>
        <div className="hr border-[#979797] mt-12"></div>
        <p className="font-raleway font-bold text-16px mt-6 mb-4 mt-4 react-text text-center md:float-right">
          @HMCVietNam 2022. All rights reserved.
        </p>
      </div>
    </div>
  );
}
